import '../App.css';
import React, { useState, useEffect, useRef} from 'react';

import SideNavbar from '../components/navbar';
import Footer from '../components/footer';

import volunteer3 from '../images/volunteer/volunteer3.webp';
import volunteer4 from '../images/volunteer/volunteer4.webp';
import volunteer5 from '../images/volunteer/volunteer5.webp';
import volunteer6 from '../images/volunteer/volunteer6.webp';
import volunteer7 from '../images/volunteer/volunteer7.webp';
import volunteer9 from '../images/volunteer/volunteer9.webp';
import volunteer10 from '../images/volunteer/volunteer10.webp';
import volunteer11 from '../images/volunteer/volunteer11.webp';
import volunteer12 from '../images/volunteer/volunteer12.webp';
import volunteer13 from '../images/volunteer/volunteer13.webp';
import volunteer14 from '../images/volunteer/volunteer14.webp';
import volunteer15 from '../images/volunteer/volunteer15.webp';
import volunteer16 from '../images/volunteer/volunteer16.webp';
import volunteer17 from '../images/volunteer/volunteer17.webp';
import volunteer18 from '../images/volunteer/volunteer18.webp';
import volunteer19 from '../images/volunteer/volunteer19.webp';
import volunteer22 from '../images/volunteer/volunteer22.webp';
import volunteer23 from '../images/volunteer/volunteer23.webp';



function Volunteer() {
  return (
    <div className='volunteer'>
        <SideNavbar/>
        <section className='volunteer-home'>
            <div className='photo-slider'>
                <div className='slide-track first'>
       		  <div className='slide'>
                    <img src={volunteer22} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer23} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer3} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer4} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer5} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer6} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer7} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer18} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer9} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer22} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer23} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer3} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer4} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer5} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer6} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer7} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer18} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer9} alt='volunteer at nonprofit'/> 
                </div>
                 </div>
            </div>
            <h1> Volunteer</h1>
            <p>At People Who Make a Difference, we believe that everyone has the potential to impact lives and create a healthier community. Our volunteers are at the heart of our mission, helping us provide essential support to the uninsured and making healthcare accessible to all. Whether you have a few hours a month or a few days a week, your time and skills can make a real difference. Email us your resume and <strong>make a difference today!</strong></p>
            <a href='https://form.jotform.com/241125182239148'> Become a Volunteer
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" height="800px" width="800px" version="1.2" baseProfile="tiny" id="Layer_1" viewBox="0 0 256 256" >
<path d="M216.2,92.5c-5.2-5.3-13.8-5.4-19-0.1L157.8,134l-0.3,0.3c-25.5,0.1-46.2,16.8-46.2,37.3h-5.8c0-23.6,23.1-42.8,51.6-43.1  l-0.1-19.4c0,0-0.1-85.4-0.1-85.5c-0.7-5.8-5.6-10.3-11.7-10.3c-6.5,0-11.7,5.3-11.7,11.7c0,0.2,0,72.9,0,72.9l-5.2,0.2l-0.1-81.2  c0-6.5-5.3-11.7-11.8-11.7s-11.7,5.3-11.7,11.8v81h-5.6l0.1-63.6c0-6.5-5.3-11.7-11.8-11.7S75.7,28,75.7,34.4V98h-5.2l0.1-34.2  c0-6.5-5.3-11.7-11.8-11.7s-11.7,5.3-11.7,11.8c0,108.4,0,108.4,0,111c0,13.2,6.8,24.4,16.2,28.1v48.4h0.2v0.4l81.2-0.4v-51.1  c2.5-0.9,5.1-2,7.6-3.3c15.4-7.9,26.1-20.3,29.5-32.5c2.9-4.3,36.8-56.2,36.8-56.2C221.4,103.7,220.6,96.9,216.2,92.5z"/>
</svg>
                        </div>
                    </a>

            <div className='photo-slider second'>
                <div className='slide-track'>
                    <div className='slide'>
                    <img src={volunteer10} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer11} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer12} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer13} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer14} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer15} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer16} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer17} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer19} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer10} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer11} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer12} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer13} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer14} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer15} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer16} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer17} alt='volunteer at nonprofit'/> 
                </div>
                <div className='slide'>
                    <img src={volunteer19} alt='volunteer at nonprofit'/> 
                </div>
                </div>
            </div>
            
        </section>
        <section className='impact-volunteer'>
              <h2>You Can Make A Difference!</h2>
              <p> Volunteering is not merely about offering a helping hand; it is a heartfelt act of spreading love, kindness, and hope. It is about lighting up someone's day, easing their burdens, and contributing positively to their lives. As a volunteer, you will engage in a variety of activities including participating in fundraisers, assisting at events, and numerous other volunteering opportunities. Each action you take helps to weave a tapestry of community support and shared joy.</p>
              <ul>
                <li>
                  <dotlottie-player className='svg'
                    src="https://lottie.host/d2fe904a-a01c-483e-a5bb-0302d7233380/AY39VXHX56.json"
                    background="transparent"
                    speed={1}
                    direction={1}
                    style={{ width: '20em', height: '20em' }}
                    loop
                    autoplay
                  />
                  <h3>Impact:</h3><h4>Directly improve lives<br/>through your actions.</h4>
                </li>
                <li>
                  <dotlottie-player 
                    src="https://lottie.host/7e602bb8-eae7-49ef-a623-a8b8f8cd662c/Pna8a60qUT.json"
                    background="transparent"
                    speed={1}
                    direction={1}
                    style={{ width: '20em', height: '20em' }}
                    loop
                    autoplay
                  />
                  <h3>Connect:</h3><h4>Be part of a community<br/>that cares.</h4>
                </li>
                <li>
                  <dotlottie-player 
                    src="https://lottie.host/25f34143-5b67-4ec9-b07b-06efd4c7377a/uV4HhzP6jY.json"
                    background="transparent"
                    speed={1}
                    style={{ width: '20em', height: '20em' }}
                    loop
                    autoplay
                  />
                  <h3>Grow:</h3><h4>Enhance your skills<br/>and build your resume.</h4>
                </li>
              </ul>
              <h5>Excited to join? We would love to hear from you! Fill out the application and let’s make a difference together!</h5> 
              <a href='https://form.jotform.com/241125182239148'> Apply Here
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" height="800px" width="800px" version="1.2" baseProfile="tiny" id="Layer_1" viewBox="0 0 256 256" >
<path d="M216.2,92.5c-5.2-5.3-13.8-5.4-19-0.1L157.8,134l-0.3,0.3c-25.5,0.1-46.2,16.8-46.2,37.3h-5.8c0-23.6,23.1-42.8,51.6-43.1  l-0.1-19.4c0,0-0.1-85.4-0.1-85.5c-0.7-5.8-5.6-10.3-11.7-10.3c-6.5,0-11.7,5.3-11.7,11.7c0,0.2,0,72.9,0,72.9l-5.2,0.2l-0.1-81.2  c0-6.5-5.3-11.7-11.8-11.7s-11.7,5.3-11.7,11.8v81h-5.6l0.1-63.6c0-6.5-5.3-11.7-11.8-11.7S75.7,28,75.7,34.4V98h-5.2l0.1-34.2  c0-6.5-5.3-11.7-11.8-11.7s-11.7,5.3-11.7,11.8c0,108.4,0,108.4,0,111c0,13.2,6.8,24.4,16.2,28.1v48.4h0.2v0.4l81.2-0.4v-51.1  c2.5-0.9,5.1-2,7.6-3.3c15.4-7.9,26.1-20.3,29.5-32.5c2.9-4.3,36.8-56.2,36.8-56.2C221.4,103.7,220.6,96.9,216.2,92.5z"/>
</svg>
                        </div>
                    </a>                
         
        </section>
        <footer><Footer/></footer>
    </div>
  );
}

export default Volunteer;
